@import url('./display.css');
@import url('./spacings.css');

.border-none {
  border: none;
}
.border-radius-full {
  border-radius: 50%;
}
.medium {
  font-weight: 600;
}
.strong {
  font-weight: 700;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.skyblue {
  background-color: #edf8ff !important;
}
.border-bottom {
  border-bottom: 1px solid #f5f5f5;
}