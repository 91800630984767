:root {
  --spacing-0: 0;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-96: 96px;
  --spacing-128: 128px;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
/* Margin utilities */

.m-0 {
  margin-top: var(--spacing-0) !important;
  margin-bottom: var(--spacing-0) !important;
  margin-left: var(--spacing-0) !important;
  margin-right: var(--spacing-0) !important;
}
.mt-0 {
  margin-top: var(--spacing-0) !important;
}

.mr-0 {
  margin-right: var(--spacing-0) !important;
}

.mb-0 {
  margin-bottom: var(--spacing-0) !important;
}

.ml-0 {
  margin-left: var(--spacing-0) !important;
}

.mx-0 {
  margin-left: var(--spacing-0) !important;
  margin-right: var(--spacing-0) !important;
}

.my-0 {
  margin-top: var(--spacing-0) !important;
  margin-bottom: var(--spacing-0) !important;
}

.m-4 {
  margin-top: var(--spacing-4) !important;
  margin-bottom: var(--spacing-4) !important;
  margin-left: var(--spacing-4) !important;
  margin-right: var(--spacing-4) !important;
}
.mt-4 {
  margin-top: var(--spacing-4) !important;
}

.mr-4 {
  margin-right: var(--spacing-4) !important;
}

.mb-4 {
  margin-bottom: var(--spacing-4) !important;
}

.ml-4 {
  margin-left: var(--spacing-4) !important;
}

.mx-4 {
  margin-left: var(--spacing-4) !important;
  margin-right: var(--spacing-4) !important;
}

.my-4 {
  margin-top: var(--spacing-4) !important;
  margin-bottom: var(--spacing-4) !important;
}

.mt-8 {
  margin-top: var(--spacing-8) !important;
}

.mr-8 {
  margin-right: var(--spacing-8) !important;
}
.m-8 {
  margin-top: var(--spacing-8) !important;
  margin-bottom: var(--spacing-8) !important;
  margin-left: var(--spacing-8) !important;
  margin-right: var(--spacing-8) !important;
}
.mb-8 {
  margin-bottom: var(--spacing-8) !important;
}

.ml-8 {
  margin-left: var(--spacing-8) !important;
}

.mx-8 {
  margin-left: var(--spacing-8) !important;
  margin-right: var(--spacing-8) !important;
}

.my-8 {
  margin-top: var(--spacing-8) !important;
  margin-bottom: var(--spacing-8) !important;
}
.mt-12 {
  margin-top: var(--spacing-12) !important;
}

.mr-12 {
  margin-right: var(--spacing-12) !important;
}
.m-12 {
  margin-top: var(--spacing-12) !important;
  margin-bottom: var(--spacing-12) !important;
  margin-left: var(--spacing-12) !important;
  margin-right: var(--spacing-12) !important;
}
.mb-12 {
  margin-bottom: var(--spacing-12) !important;
}

.ml-12 {
  margin-left: var(--spacing-12) !important;
}

.mx-12 {
  margin-left: var(--spacing-12) !important;
  margin-right: var(--spacing-12) !important;
}

.my-12 {
  margin-top: var(--spacing-12) !important;
  margin-bottom: var(--spacing-12) !important;
}
.m-16 {
  margin-top: var(--spacing-16) !important;
  margin-bottom: var(--spacing-16) !important;
  margin-left: var(--spacing-16) !important;
  margin-right: var(--spacing-16) !important;
}
.mt-16 {
  margin-top: var(--spacing-16) !important;
}

.mr-16 {
  margin-right: var(--spacing-16) !important;
}

.mb-16 {
  margin-bottom: var(--spacing-16) !important;
}

.ml-16 {
  margin-left: var(--spacing-16) !important;
}

.mx-16 {
  margin-left: var(--spacing-16) !important;
  margin-right: var(--spacing-16) !important;
}

.my-16 {
  margin-top: var(--spacing-16) !important;
  margin-bottom: var(--spacing-16) !important;
}
/*  */
.mb-20 {
  margin-bottom: var(--spacing-20) !important;
}
/*  */
.m-24 {
  margin-top: var(--spacing-24) !important;
  margin-bottom: var(--spacing-24) !important;
  margin-left: var(--spacing-24) !important;
  margin-right: var(--spacing-24) !important;
}
.mt-24 {
  margin-top: var(--spacing-24) !important;
}

.mr-24 {
  margin-right: var(--spacing-24) !important;
}

.mb-24 {
  margin-bottom: var(--spacing-24) !important;
}

.ml-24 {
  margin-left: var(--spacing-24) !important;
}

.mx-24 {
  margin-left: var(--spacing-24) !important;
  margin-right: var(--spacing-24) !important;
}

.my-24 {
  margin-top: var(--spacing-24) !important;
  margin-bottom: var(--spacing-24) !important;
}
/*  */
.m-32 {
  margin-top: var(--spacing-32) !important;
  margin-bottom: var(--spacing-32) !important;
  margin-left: var(--spacing-32) !important;
  margin-right: var(--spacing-32) !important;
}
.mt-32 {
  margin-top: var(--spacing-32) !important;
}

.mr-32 {
  margin-right: var(--spacing-32) !important;
}

.mb-32 {
  margin-bottom: var(--spacing-32) !important;
}

.ml-32 {
  margin-left: var(--spacing-32) !important;
}

.mx-32 {
  margin-left: var(--spacing-32) !important;
  margin-right: var(--spacing-32) !important;
}

.my-32 {
  margin-top: var(--spacing-32) !important;
  margin-bottom: var(--spacing-32) !important;
}
.m-48 {
  margin-top: var(--spacing-48) !important;
  margin-bottom: var(--spacing-48) !important;
  margin-left: var(--spacing-48) !important;
  margin-right: var(--spacing-48) !important;
}
.mt-48 {
  margin-top: var(--spacing-48) !important;
}

.mr-48 {
  margin-right: var(--spacing-48) !important;
}

.mb-48 {
  margin-bottom: var(--spacing-48) !important;
}
.mb-96 {
  margin-bottom: var(--spacing-96) !important;
}
.ml-48 {
  margin-left: var(--spacing-48) !important;
}

.mx-48 {
  margin-left: var(--spacing-48) !important;
  margin-right: var(--spacing-48) !important;
}

.my-48 {
  margin-top: var(--spacing-48) !important;
  margin-bottom: var(--spacing-48) !important;
}
.m-64 {
  margin-top: var(--spacing-64) !important;
  margin-bottom: var(--spacing-64) !important;
  margin-left: var(--spacing-64) !important;
  margin-right: var(--spacing-64) !important;
}
.mt-64 {
  margin-top: var(--spacing-64) !important;
}

.mr-64 {
  margin-right: var(--spacing-64) !important;
}

.mb-64 {
  margin-bottom: var(--spacing-64) !important;
}

.ml-64 {
  margin-left: var(--spacing-64) !important;
}

.mx-64 {
  margin-left: var(--spacing-64) !important;
  margin-right: var(--spacing-64) !important;
}

.my-64 {
  margin-top: var(--spacing-64) !important;
  margin-bottom: var(--spacing-64) !important;
}

/*  */
.m-72 {
  margin-top: var(--spacing-72) !important;
  margin-bottom: var(--spacing-72) !important;
  margin-left: var(--spacing-72) !important;
  margin-right: var(--spacing-72) !important;
}
.mt-72 {
  margin-top: var(--spacing-72) !important;
}

.mr-72 {
  margin-right: var(--spacing-72) !important;
}

.mb-72 {
  margin-bottom: var(--spacing-72) !important;
}

.ml-72 {
  margin-left: var(--spacing-72) !important;
}

.mx-72 {
  margin-left: var(--spacing-72) !important;
  margin-right: var(--spacing-72) !important;
}

.my-72 {
  margin-top: var(--spacing-72) !important;
  margin-bottom: var(--spacing-72) !important;
}
/*  */
/*  */
.m-96 {
  margin-top: var(--spacing-96) !important;
  margin-bottom: var(--spacing-96) !important;
  margin-left: var(--spacing-96) !important;
  margin-right: var(--spacing-96) !important;
}
.mt-96 {
  margin-top: var(--spacing-96) !important;
}

.mr-96 {
  margin-right: var(--spacing-96) !important;
}

.mb-96 {
  margin-bottom: var(--spacing-96) !important;
}

.ml-96 {
  margin-left: var(--spacing-96) !important;
}

.mx-96 {
  margin-left: var(--spacing-96) !important;
  margin-right: var(--spacing-96) !important;
}

.my-96 {
  margin-top: var(--spacing-96) !important;
  margin-bottom: var(--spacing-96) !important;
}
/*  */
.m-128 {
  margin-top: var(--spacing-128) !important;
  margin-bottom: var(--spacing-128) !important;
  margin-left: var(--spacing-128) !important;
  margin-right: var(--spacing-128) !important;
}
.mt-128 {
  margin-top: var(--spacing-128) !important;
}
.mr-128 {
  margin-right: var(--spacing-128) !important;
}
.mb-128 {
  margin-bottom: var(--spacing-128) !important;
}
.ml-128 {
  margin-left: var(--spacing-128) !important;
}
.mx-128 {
  margin-top: var(--spacing-128) !important;
  margin-bottom: var(--spacing-128) !important;
}
.my-128 {
  margin-top: var(--spacing-128) !important;
  margin-bottom: var(--spacing-128) !important;
}
/*  */

/* Paddings */
.p-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.p-4 {
  padding-top: var(--spacing-4) !important;
  padding-bottom: var(--spacing-4) !important;
  padding-left: var(--spacing-4) !important;
  padding-right: var(--spacing-4) !important;
}
.pt-4 {
  padding-top: var(--spacing-4) !important;
}

.pr-4 {
  padding-right: var(--spacing-4) !important;
}

.pb-4 {
  padding-bottom: var(--spacing-4) !important;
}

.pl-4 {
  padding-left: var(--spacing-4) !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-4 {
  padding-left: var(--spacing-4) !important;
  padding-right: var(--spacing-4) !important;
}
.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.py-4 {
  padding-top: var(--spacing-4) !important;
  padding-bottom: var(--spacing-4) !important;
}

.p-8 {
  padding-top: var(--spacing-8) !important;
  padding-bottom: var(--spacing-8) !important;
  padding-left: var(--spacing-8) !important;
  padding-right: var(--spacing-8) !important;
}
.pt-8 {
  padding-top: var(--spacing-8) !important;
}

.pr-8 {
  padding-right: var(--spacing-8) !important;
}

.pb-8 {
  padding-bottom: var(--spacing-8) !important;
}

.pl-8 {
  padding-left: var(--spacing-8) !important;
}

.px-8 {
  padding-left: var(--spacing-8) !important;
  padding-right: var(--spacing-8) !important;
}

.py-8 {
  padding-top: var(--spacing-8) !important;
  padding-bottom: var(--spacing-8) !important;
}
.p-12 {
  padding-top: var(--spacing-12) !important;
  padding-bottom: var(--spacing-12) !important;
  padding-left: var(--spacing-12) !important;
  padding-right: var(--spacing-12) !important;
}
.pt-12 {
  padding-top: var(--spacing-12) !important;
}

.pr-12 {
  padding-right: var(--spacing-12) !important;
}

.pb-12 {
  padding-bottom: var(--spacing-12) !important;
}

.pl-12 {
  padding-left: var(--spacing-12) !important;
}

.px-12 {
  padding-left: var(--spacing-12) !important;
  padding-right: var(--spacing-12) !important;
}

.py-12 {
  padding-top: var(--spacing-12) !important;
  padding-bottom: var(--spacing-12) !important;
}

.p-16 {
  padding-top: var(--spacing-16) !important;
  padding-bottom: var(--spacing-16) !important;
  padding-left: var(--spacing-16) !important;
  padding-right: var(--spacing-16) !important;
}
.pt-16 {
  padding-top: var(--spacing-16) !important;
}

.pr-16 {
  padding-right: var(--spacing-16) !important;
}

.pb-16 {
  padding-bottom: var(--spacing-16) !important;
}

.pl-16 {
  padding-left: var(--spacing-16) !important;
}

.px-16 {
  padding-left: var(--spacing-16) !important;
  padding-right: var(--spacing-16) !important;
}

.py-16 {
  padding-top: var(--spacing-16) !important;
  padding-bottom: var(--spacing-16) !important;
}

.p-24 {
  padding-top: var(--spacing-24) !important;
  padding-bottom: var(--spacing-24) !important;
  padding-left: var(--spacing-24) !important;
  padding-right: var(--spacing-24) !important;
}
.pt-24 {
  padding-top: var(--spacing-24) !important;
}

.pr-24 {
  padding-right: var(--spacing-24) !important;
}
.pb-2 {
  padding-bottom: var(--spacing-2) !important;
}

.pb-24 {
  padding-bottom: var(--spacing-24) !important;
}

.pl-24 {
  padding-left: var(--spacing-24) !important;
}

.px-24 {
  padding-left: var(--spacing-24) !important;
  padding-right: var(--spacing-24) !important;
}

.py-24 {
  padding-top: var(--spacing-24) !important;
  padding-bottom: var(--spacing-24) !important;
}

.p-32 {
  padding-top: var(--spacing-32) !important;
  padding-bottom: var(--spacing-32) !important;
  padding-left: var(--spacing-32) !important;
  padding-right: var(--spacing-32) !important;
}
.pt-32 {
  padding-top: var(--spacing-32) !important;
}

.pr-32 {
  padding-right: var(--spacing-32) !important;
}

.pb-32 {
  padding-bottom: var(--spacing-32) !important;
}

.pl-32 {
  padding-left: var(--spacing-32) !important;
}

.px-32 {
  padding-left: var(--spacing-32) !important;
  padding-right: var(--spacing-32) !important;
}

.py-32 {
  padding-top: var(--spacing-32) !important;
  padding-bottom: var(--spacing-32) !important;
}
.p-48 {
  padding-top: var(--spacing-48) !important;
  padding-bottom: var(--spacing-48) !important;
  padding-left: var(--spacing-48) !important;
  padding-right: var(--spacing-48) !important;
}

.pt-48 {
  padding-top: var(--spacing-48) !important;
}

.pr-48 {
  padding-right: var(--spacing-48) !important;
}

.pb-48 {
  padding-bottom: var(--spacing-48) !important;
}

.pl-48 {
  padding-left: var(--spacing-48) !important;
}

.px-48 {
  padding-left: var(--spacing-48) !important;
  padding-right: var(--spacing-48) !important;
}

.py-48 {
  padding-top: var(--spacing-48) !important;
  padding-bottom: var(--spacing-48) !important;
}
.p-64 {
  padding-top: var(--spacing-64) !important;
  padding-bottom: var(--spacing-64) !important;
  padding-left: var(--spacing-64) !important;
  padding-right: var(--spacing-64) !important;
}

.pt-64 {
  padding-top: var(--spacing-64) !important;
}

.pr-64 {
  padding-right: var(--spacing-64) !important;
}

.pb-64 {
  padding-bottom: var(--spacing-64) !important;
}

.pl-64 {
  padding-left: var(--spacing-64) !important;
}

.px-64 {
  padding-left: var(--spacing-64) !important;
  padding-right: var(--spacing-64) !important;
}

.py-64 {
  padding-top: var(--spacing-64) !important;
  padding-bottom: var(--spacing-64) !important;
}

.p-128 {
  padding-top: var(--spacing-128) !important;
  padding-bottom: var(--spacing-128) !important;
  padding-left: var(--spacing-128) !important;
  padding-right: var(--spacing-128) !important;
}

.pt-128 {
  padding-top: var(--spacing-128) !important;
}
.pr-128 {
  padding-right: var(--spacing-128) !important;
}
.pb-128 {
  padding-bottom: var(--spacing-128) !important;
}
.pl-128 {
  padding-left: var(--spacing-128) !important;
}
.px-128 {
  padding-top: var(--spacing-128) !important;
  padding-bottom: var(--spacing-128) !important;
}
.py-128 {
  padding-top: var(--spacing-128) !important;
  padding-bottom: var(--spacing-128) !important;
}

/* Gap */

.gap-4 {
  gap: var(--spacing-4) !important;
}
.gap-8 {
  gap: var(--spacing-8) !important;
}
.gap-12 {
  gap: var(--spacing-12) !important;
}
.gap-16 {
  gap: var(--spacing-16) !important;
}
.gap-24 {
  gap: var(--spacing-24) !important;
}
.gap-32 {
  gap: var(--spacing-32) !important;
}
.gap-64 {
  gap: var(--spacing-64) !important;
}
.gap-128 {
  gap: var(--spacing-128) !important;
}

/* Row gap */
.row-gap-4 {
  row-gap: var(--spacing-4) !important;
}
.row-gap-8 {
  row-gap: var(--spacing-8) !important;
}
.row-gap-12 {
  row-gap: var(--spacing-12) !important;
}
.row-gap-16 {
  row-gap: var(--spacing-16) !important;
}
.row-gap-24 {
  row-gap: var(--spacing-24) !important;
}
.row-gap-32 {
  row-gap: var(--spacing-32) !important;
}
.row-gap-64 {
  row-gap: var(--spacing-64) !important;
}
.row-gap-128 {
  row-gap: var(--spacing-128) !important;
}
