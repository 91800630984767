.app-layout {
  height: inherit;
  width: inherit;
}

.app-sider {
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: auto;
}