:root {
  --border-color-active: #91caff;
  --border-color-default: #f0f0f0;
  --text-color-default: #000;
}

.character {
  width: 50px;
  border: none;
  border-bottom: 1px solid;
  border-color: var(--border-color-default);
}

.character-inactive {
  background-color: transparent;
}

.character-selected {
  color: var(--text-color-default);
  border-color: var(--border-color-active);
  outline: none;
}
