.search-popup {
  top: 64px !important;
  left: 240px !important;
  width: calc(100vw - 248px) !important;
}

.search-popup-collapsed {
  top: 64px !important;
  left: 80px !important;
  width: calc(100vw - 80px) !important;
}

.search-popup-xs {
  top: 116px !important;
  left: 0px !important;
  right: 0px !important;
  width: 100vw !important;
}