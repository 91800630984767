@import 'react-phone-input-2/lib/style.css';

.react-tel-input .form-control {
  width: 100%;
}

.selected-flag:hover {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  overflow: hidden;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  overflow: hidden;
}