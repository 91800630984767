.blurry-content {
  position: relative;
  overflow: hidden;
}

.blurry-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #F5F5F5 80%); 
  pointer-events: none; 
}
