*,
*:before,
*:after {
  box-sizing: border-box;
}
.ant-input-number {
  width: 100%;
}

body {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-weight: 700 !important;
}
:focus-visible {
  outline: none;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.45);
}
/* Edge and IE */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
/* Scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
}
:focus-visible {
  outline: none;
}
/* Hide Calendar Icon In Chrome */
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.ant-form-item-label {
  padding: 4px !important;
}

.map-container {
  width: 100% !important;
  height: 100% !important;
}
.map-container-circle {
  width: 100% !important;
  height: 50vh !important;
  border-radius: 50%;
}
.gmnoprint div,
.gm-fullscreen-control,
.gm-svpc {
  border-radius: 8px !important;
}

.map-container div[role='menubar'] .gm-style-mtc:first-child button {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.map-container div[role='menubar'] .gm-style-mtc:last-child button {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.current-location-button {
  background: none padding-box rgb(255, 255, 255);
  border: 0px;
  margin: 12px 0px;
  padding: 0px 17px;
  position: absolute !important;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  height: 40px;
  vertical-align: middle;
  color: rgb(86, 86, 86);
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 79px;
  left: 50% !important;
  transform: translateX(-50%);
  white-space: nowrap;
}

.current-location-button:hover {
  background-color: rgb(235, 235, 235);
  color: #000;
}

/* Ant form  */
.ant-form-item-label {
  padding-bottom: 8px !important;
}

#date-range-popover {
  padding: 24px;
  width: 576px;
}

.categories-drawer .ant-drawer-content-wrapper {
  top: 56px;
  bottom: 64px;
  height: unset !important;
  box-shadow: unset;
}
.categories-drawer .ant-drawer-body {
  background: #f5f5f5;
  padding: 20px;
  padding-bottom: 54px;
}
.categories-drawer .ant-drawer-mask {
  display: none;
}

.search-header-drawer {
  z-index: 10;
}
.search-header-drawer .ant-drawer-mask,
.search-header-drawer .ant-drawer-header {
  display: none;
}

.search-header-drawer .ant-drawer-content-wrapper {
  height: unset !important;
  top: 56px;
  left: 0;
  right: 0;
}

.search-header-drawer .ant-drawer-body {
  padding: 0;
}

.ant-popover-inner:has(.xs-date-range-picker){
  max-width: 400px;
}
.xs-date-range-picker .rdrMonths.rdrMonthsHorizontal{
  display: flex !important;
  gap: 8px;
}
