/* Cart popover */
.cart-popover {
  padding: 0px;
  box-shadow: revert;
  top: 12px !important;
  right: revert !important;
  left: 254px !important;
  bottom: 12px !important;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important;
}
.cart-popover-collapsed {
  padding: 0px;
  box-shadow: revert;
  top: 12px !important;
  right: revert !important;
  left: 96px !important;
  bottom: 12px !important;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important;
}

.cart-popover .ant-popover-content,
.cart-popover-collapsed .ant-popover-content {
  height: calc(100vh - 24px) !important;
  background-color: #fff;
}

.cart-popover .ant-popover-inner,
.cart-popover-collapsed .ant-popover-inner {
  box-shadow: none;
  padding: 16px;
}