@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('./App.css');
@import url('./googleButton.css');
@import url('./helpers/helpers.css');

.ant-ribbon-wrapper {
  height: 100%;
}

.tooltip-white-bg .ant-tooltip-inner{
  background-color:white !important;
}
